import { useAsyncValue } from '../utils/CustomHooksUtil'
import axios from 'axios'

export function useAuditSourceNames() {
    return useAsyncValue(() => {
        const endPoint = `/api/audit/testSources`
        return axios.get(endPoint).then(({ data }) => {
            return data.filter((source) => source != null)
        })
    }, [])
}
