import { useAuditSourceNames } from '../../service/auditService'
import { React, useState } from 'react'
import axios from 'axios'
import { Container } from '../../components/Container'
import { PageHeader } from '../../components/PageHeader'
import { SelectInput } from '@interstate/components/SelectInput'
import { Button } from '@interstate/components/Button'
import { Box } from '@interstate/components/Box'
import { Table } from '@interstate/components/Table'
import { AskToDelete } from '../../components/AskTo'
import { PatErrorAlert } from '../../components/common/PatErrorAlert'
import { IndividualAudit } from '../../components/audit/IndividualAudit'

export default function AuditTestSources() {
    const {
        value: testSourcesList,
        loading: testSourcesLoading,
        error: testSourcesError
    } = useAuditSourceNames()

    const [selectedSource, setSelectedSource] = useState(null)
    const [selectedAudit, setSelectedAudit] = useState(null)
    const [data, setData] = useState([])
    const [mapData, setMapData] = useState([])
    const [counters, setCounters] = useState({})
    const [totalIncorrect, setTotalIncorrect] = useState(0)
    const [showTestSourceDeleteModal, setShowTestSourceDeleteModal] =
        useState(false)
    const handleInputChange = (e) => {
        setSelectedSource(e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        axios
            .get(`/api/audit/testSourceByName?testSource=${selectedSource}`)
            .then((response) => {
                let data = response.data
                // data = data.sort((a, b) => a.feeCategory - b.feeCategory);
                data = data.map((item) => {
                    item['key'] = item.sblu + item.feeType
                    return item
                })
                // data = data.map((item) => {item['sellerG'] = `${item.sellerGroup || ''} | ${item.sellerSubGroup || ''}`; return item;})
                // data = data.map((item) => {item['buyerG'] = `${item.buyerGroup || ''} | ${item.buyerSubGroup || ''}`; return item;})
                let ndata = {}
                data.forEach((item) => {
                    let key = item.sblu + item.buCode
                    if (ndata[key]) {
                        ndata[key].push(item)
                    } else {
                        ndata[key] = [item]
                    }
                })
                setMapData(ndata)
                setData(data)

                let counter = {}
                let incorrect = 0

                data.forEach((item) => {
                    if (item.as400CalculatedFee !== item.patCalculatedFee) {
                        if (!counter.hasOwnProperty(item.feeCategory)) {
                            counter[item.feeCategory] = 0
                        }
                        counter[item.feeCategory] =
                            counter[item.feeCategory] + 1
                        incorrect++
                    }
                })
                setCounters(counter)
                setTotalIncorrect(incorrect)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    function deleteTest(e) {
        e.preventDefault()
        setShowTestSourceDeleteModal(true)
    }

    function confirmDeleteTestSource() {
        axios
            .delete(`/api/audit/testSourceByName?testSource=${selectedSource}`)
            .then((response) => {
                window.location.reload()
                setShowTestSourceDeleteModal(false)
            })
    }

    if (selectedSource === null && !testSourcesLoading) {
        setSelectedSource(testSourcesList[0] || null)
    }
    console.log(mapData)
    return (
        <Container>
            <PageHeader titleValue="Audit Test Sources" />
            <PatErrorAlert error={testSourcesError} />
            {!testSourcesLoading && (
                <SelectInput
                    name="Test Source"
                    className="me-2"
                    overrideBaseCss={true}
                    options={testSourcesList.map((source) => {
                        return { value: source, label: source }
                    })}
                    value={selectedSource}
                    maxHeight={'200px'}
                    onChange={handleInputChange}></SelectInput>
            )}
            <Box marginTop="10px">
                <Button onClick={handleSubmit} sx={{ marginRight: '10px' }}>
                    Submit
                </Button>
                <Button buttonStyle="secondary" onClick={deleteTest}>
                    Delete
                </Button>
            </Box>

            <AskToDelete
                show={showTestSourceDeleteModal}
                cancel={() => setShowTestSourceDeleteModal(false)}
                handle={confirmDeleteTestSource}
                data={{ testSource: selectedSource }}
                attribute={'testSource'}
            />

            {data.length > 0 && (
                <div>
                    {Object.keys(counters).map((key) => (
                        <h2 key={key}>
                            {key}: {counters[key]}{' '}
                        </h2>
                    ))}
                    <h2>
                        Total Incorrect: {totalIncorrect} / {data.length} ={' '}
                        {((totalIncorrect / data.length) * 100).toFixed(2)} %
                    </h2>
                </div>
            )}

            {!selectedAudit && (
                <Table
                    tableLayout="fixed"
                    // sortableColumns={true}
                    dataDensity="small"
                    // displayFilter={true}
                    // highlightOnHover={true}
                    headerBackgroundColor="dark"
                    rowClassName={(record) => {
                        let reply = 'tableGood'
                        mapData[record]?.forEach((item) => {
                            if (
                                item.as400CalculatedFee !==
                                item.patCalculatedFee
                            ) {
                                reply = 'tableBad'
                            }
                        })
                        return reply
                    }}
                    columns={[
                        {
                            title: 'Index',
                            render: (item) => (
                                <span>
                                    <div>
                                        <b>Auction:</b>{' '}
                                        {mapData[item][0]?.buCode}{' '}
                                    </div>
                                    <div
                                        onClick={() => {
                                            setSelectedAudit(item)
                                        }}>
                                        <b>SBLU:</b> {mapData[item][0]?.sblu}
                                    </div>
                                    <div>
                                        <b>WO#:</b>{' '}
                                        {mapData[item][0]?.workOrderNumber}
                                    </div>
                                    <div>
                                        <b>UKEY:</b>{' '}
                                        {mapData[item][0]?.saleYear}-
                                        {mapData[item][0]?.saleNumber}-
                                        {mapData[item][0]?.lane}-
                                        {mapData[item][0]?.runNumber}
                                    </div>
                                </span>
                            )
                        },
                        {
                            title: 'Sale',
                            render: (item) => (
                                <span>
                                    <div>
                                        <b>Contract Type:</b>{' '}
                                        {mapData[item][0]?.contractType}
                                    </div>
                                    <div>
                                        <b>Sale Type:</b>{' '}
                                        {mapData[item][0]?.saleType}
                                    </div>
                                    <div>
                                        <b>Buyer Table:</b>{' '}
                                        {mapData[item][0]?.buyerFeeTable}
                                    </div>
                                    <div>
                                        <b>Seller Type:</b>{' '}
                                        {mapData[item][0]?.sellerFeeTable}
                                    </div>
                                    <div>
                                        <b>Sale Price: </b>{' '}
                                        {mapData[item][0]?.salePrice}
                                    </div>
                                </span>
                            )
                        },
                        {
                            title: 'Customer',
                            render: (item) => (
                                <span>
                                    <div>
                                        <b>Buyer / Group / Alt</b>
                                    </div>
                                    <span>{mapData[item][0]?.buyer} / </span>
                                    <span>
                                        {mapData[item][0]?.buyerGroup} /{' '}
                                    </span>
                                    <span>
                                        {mapData[item][0]?.buyerSubGroup}
                                    </span>
                                    <div>
                                        <b>Seller / Group / Alt</b>
                                    </div>
                                    <span>{mapData[item][0]?.seller} / </span>
                                    <span>
                                        {mapData[item][0]?.sellerGroup} /{' '}
                                    </span>
                                    <span>
                                        {mapData[item][0]?.sellerSubGroup}
                                    </span>
                                </span>
                            )
                        },
                        {
                            title: 'Fees',
                            render: (item) => (
                                <span>
                                    <div>
                                        <b>(Fee Type) Pat / AS400</b>
                                    </div>
                                    {mapData[item].map((fee) => {
                                        return (
                                            <div>
                                                <span>{fee.feeType} </span>
                                                <span>
                                                    {fee.patCalculatedFee}
                                                </span>
                                                <span>
                                                    {' '}
                                                    / {fee.as400CalculatedFee}
                                                </span>
                                            </div>
                                        )
                                    })}
                                </span>
                            )
                        }
                    ]}
                    data={Object.keys(mapData)}
                />
            )}
            {selectedAudit && (
                <div>
                    <button onClick={() => setSelectedAudit(null)}>Back</button>
                    <IndividualAudit data={mapData[selectedAudit]} />
                </div>
            )}
        </Container>
    )
}
