import { TrashIcon } from '@interstate/components/Icons'
import { useState } from 'react'
import { AskToDelete } from './AskTo'
import { Typography } from '@interstate/components/Typography'
import { Grid } from '@interstate/components/Grid'
import { Action } from '@interstate/components/Action'

export function SaleTypeFeeHeader({ stflIndex, onDataChange }) {
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false)
    return (
        <>
            <Grid container justifyContent="space-between" marginBottom="15px">
                <Grid item>
                    <Typography
                        data-testid={
                            'heading_SaleType_stflI_' + (stflIndex + 1)
                        }
                        variant="h5">
                        Sale Type #{stflIndex + 1}
                    </Typography>
                </Grid>
                <Grid item>
                    <Action
                        data-testid={'action_Delete_stflI_' + (stflIndex + 1)}
                        size="sm"
                        actionIcon={{
                            icon: <TrashIcon />,
                            start: true
                        }}
                        onClick={() => setShowConfirmDeleteModal(true)}>
                        Delete
                    </Action>
                </Grid>
            </Grid>
            <AskToDelete
                show={showConfirmDeleteModal}
                handle={() => {
                    onDataChange(null)
                    setShowConfirmDeleteModal(false)
                }}
                attribute="Sale Type"
                cancel={() => setShowConfirmDeleteModal(false)}
                deleteTestId={'modal_Delete_stflI_' + (stflIndex + 1)}
                cancelTestId={'modal_Cancel_stflI_' + (stflIndex + 1)}
            />
        </>
    )
}
