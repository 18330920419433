import { React, useState } from 'react'
import { filterRateCards } from '../../utils/rate.card.helper'
import { validateSearchRateCard } from '../../utils/fee.schedule.helper'
import { Grid } from '@interstate/components/Grid'
import { TextInput } from '@interstate/components/TextInput'
import { Button } from '@interstate/components/Button'
import { Alert } from '@interstate/components/Alert'
import { RateCardsTable } from './RateCardsTable'

export function RateCardSearch({
    allRateCardsList,
    onRateCardSelection,
    toggleRateCardCreateModal,
    moduleid
}) {
    const [numTiers, setNumTiers] = useState()
    const [startFee, setStartFee] = useState()
    const [tier2Fee, setTier2Fee] = useState()
    const [tier3Fee, setTier3Fee] = useState()
    const [tier4Fee, setTier4Fee] = useState()
    const [errorMessages, setErrorMessages] = useState()
    const [filteredRateCards, setFilteredRateCards] = useState()

    function searchRateCards() {
        // validate fields
        setErrorMessages(null)
        let newErrorMessages = validateSearchRateCard({
            numTiers,
            startFee,
            tier2Fee,
            tier3Fee,
            tier4Fee
        })
        if (Object.keys(newErrorMessages).length > 0) {
            setErrorMessages(newErrorMessages)
            return false
        }

        const filteredCards = filterRateCards({
            allRateCardsList,
            numTiers,
            startFee,
            tier2Fee,
            tier3Fee,
            tier4Fee
        })
        setFilteredRateCards(filteredCards)
    }

    function handleKeyDown(event) {
        if (event.key === 'Enter') {
            searchRateCards()
        }
    }

    return (
        <>
            <Grid
                container
                columnSpacing="15px"
                data-testid={'container_SearchRateCard' + moduleid}>
                <Grid item xs={6} marginBottom={'10px'}>
                    <TextInput
                        required
                        label="Number of tiers"
                        name="numTiers"
                        value={numTiers}
                        onChange={(e) => {
                            setNumTiers(e.target.value)
                        }}
                        onKeyDown={handleKeyDown}
                        hasError={errorMessages?.numTiers}
                        errorMessage={errorMessages?.numTiers}
                        data-testid={'textinput_NumberOfTiers' + moduleid}
                    />
                </Grid>
                <Grid item xs={6} marginBottom={'10px'}>
                    <TextInput
                        required
                        label="Start fee"
                        name="startFee"
                        value={startFee}
                        onChange={(e) => {
                            setStartFee(e.target.value)
                        }}
                        onKeyDown={handleKeyDown}
                        hasError={errorMessages?.startFee}
                        errorMessage={errorMessages?.startFee}
                        data-testid={'textinput_StartFee' + moduleid}
                    />
                </Grid>
                <Grid item xs={4} marginBottom={'10px'}>
                    <TextInput
                        label="Tier 2 fee"
                        name="tier2Fee"
                        value={tier2Fee}
                        onChange={(e) => {
                            setTier2Fee(e.target.value)
                        }}
                        onKeyDown={handleKeyDown}
                        hasError={errorMessages?.tier2Fee}
                        errorMessage={errorMessages?.tier2Fee}
                        data-testid={'textinput_Tier2Fee' + moduleid}
                    />
                </Grid>
                <Grid item xs={4} marginBottom={'10px'}>
                    <TextInput
                        label="Tier 3 fee"
                        name="tier3Fee"
                        value={tier3Fee}
                        onChange={(e) => {
                            setTier3Fee(e.target.value)
                        }}
                        onKeyDown={handleKeyDown}
                        hasError={errorMessages?.tier3Fee}
                        errorMessage={errorMessages?.tier3Fee}
                        data-testid={'textinput_Tier3Fee' + moduleid}
                    />
                </Grid>
                <Grid item xs={4} marginBottom={'10px'}>
                    <TextInput
                        label="Tier 4 fee"
                        name="tier4Fee"
                        value={tier4Fee}
                        onChange={(e) => {
                            setTier4Fee(e.target.value)
                        }}
                        onKeyDown={handleKeyDown}
                        hasError={errorMessages?.tier4Fee}
                        errorMessage={errorMessages?.tier4Fee}
                        data-testid={'textinput_Tier4Fee' + moduleid}
                    />
                </Grid>
            </Grid>
            <Grid container justifyContent="space-between" marginBottom="15px">
                <Grid item>
                    <Button
                        buttonStyle="secondary"
                        onClick={toggleRateCardCreateModal}
                        data-testid={'button_CreateNewRateCard' + moduleid}>
                        Create New Rate Card
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        onClick={searchRateCards}
                        data-testid={'button_SearchRateCard' + moduleid}>
                        Search
                    </Button>
                </Grid>
            </Grid>
            <Grid
                container
                columnSpacing={'15px'}
                data-testid={'container_RateCardTable' + moduleid}>
                <Grid item xs={12}>
                    {filteredRateCards && filteredRateCards.length > 0 && (
                        <RateCardsTable
                            data={filteredRateCards}
                            onRowClick={(row) => onRateCardSelection(row)}
                            moduleid={moduleid}
                        />
                    )}
                    {filteredRateCards && filteredRateCards.length === 0 && (
                        <Alert
                            type="caution"
                            data-testid={'alert_NoResults' + moduleid}>
                            <span>
                                No rate cards matching the search criteria
                            </span>
                        </Alert>
                    )}
                </Grid>
            </Grid>
        </>
    )
}
