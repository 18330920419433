import { useSaleTypes } from '../../service/configService'
import { Alert } from '@interstate/components/Alert'
import { Select } from '@interstate/components/SelectInput'

export function SaleTypesComboBox({
    label = 'Sale Type',
    value,
    onChange,
    multiple = false,
    disabled,
    required,
    name,
    'data-testid': testid = 'sale-types-combobox'
}) {
    const {
        value: saleTypes,
        loading: saleTypesLoading,
        error: saleTypesError
    } = useSaleTypes(0)
    const configValues = saleTypes?.map((saleType) => {
        return {
            'data-testid': saleType.name,
            label: saleType.name,
            value: saleType.name
        }
    })

    return (
        <>
            {saleTypesError && (
                <Alert type="error" title="Error">
                    Something went wrong!
                </Alert>
            )}
            {!saleTypesLoading && (
                <Select
                    name={name}
                    label={label}
                    data-testid={testid}
                    options={configValues}
                    onChange={onChange}
                    value={value}
                    multiple={multiple}
                    disabled={disabled}
                    required={required}
                    placeholder="Select"
                />
            )}
        </>
    )
}
