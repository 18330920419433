import { Button } from '@interstate/components/Button'
import React from 'react'

export function MockUserForm({ username, title }) {
    return (
        <form method="POST" action="/auth/login">
            <br />
            <input type="hidden" name="email" value={username} />
            <input type="hidden" name="pass" value="r0ck3t" />
            <Button buttonStyle="secondary" type="submit">
                {username}
            </Button>
        </form>
    )
}
