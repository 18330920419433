import { useAuth } from '../auth/AuthProvider'
import { SelectInput } from '@interstate/components/SelectInput'
import { FEE_SCHEDULE_TYPE, PAT_ROLE } from '../resources/enum.constants'

export function FeeScheduleTypeSelector({ name, onChange, value, moduleID }) {
    const authService = useAuth()
    const role = authService?.principal?.role
    let options = []
    let displayValue = value || ''

    if ([PAT_ROLE.ADMIN, PAT_ROLE.BOC_EDIT].includes(role)) {
        options.push({
            label: FEE_SCHEDULE_TYPE.GLOBAL_OVERRIDES,
            value: FEE_SCHEDULE_TYPE.GLOBAL_OVERRIDES
        })
        options.push({
            label: FEE_SCHEDULE_TYPE.CONTRACT_OVERRIDES,
            value: FEE_SCHEDULE_TYPE.CONTRACT_OVERRIDES
        })
        options.push({
            label: FEE_SCHEDULE_TYPE.NATIONAL_CONTRACTS,
            value: FEE_SCHEDULE_TYPE.NATIONAL_CONTRACTS
        })
    }

    if (
        [PAT_ROLE.ADMIN, PAT_ROLE.BOC_EDIT, PAT_ROLE.AUCTION_EDIT].includes(
            role
        )
    ) {
        options.push({
            label: FEE_SCHEDULE_TYPE.LOCAL_DEALS,
            value: FEE_SCHEDULE_TYPE.LOCAL_DEALS
        })
        if (role === PAT_ROLE.AUCTION_EDIT && !value) {
            displayValue = FEE_SCHEDULE_TYPE.LOCAL_DEALS
            onChange({ target: { value: FEE_SCHEDULE_TYPE.LOCAL_DEALS } })
        }
    }

    if ([PAT_ROLE.ADMIN, PAT_ROLE.BOC_EDIT].includes(role)) {
        options.push({
            label: FEE_SCHEDULE_TYPE.CONTRACTS_BLD,
            value: FEE_SCHEDULE_TYPE.CONTRACTS_BLD
        })
    }

    if (
        [PAT_ROLE.ADMIN, PAT_ROLE.BOC_EDIT, PAT_ROLE.PRICING_EDIT].includes(
            role
        )
    ) {
        options.push({
            label: FEE_SCHEDULE_TYPE.DEFAULTS,
            value: FEE_SCHEDULE_TYPE.DEFAULTS
        })
        if (role === PAT_ROLE.PRICING_EDIT && !value) {
            displayValue = FEE_SCHEDULE_TYPE.DEFAULTS
            onChange({ target: { value: FEE_SCHEDULE_TYPE.DEFAULTS } })
        }
    }

    return (
        <SelectInput
            label="Fee Schedule Type"
            data-testid={moduleID}
            name={name}
            onChange={onChange}
            value={displayValue}
            options={options}
            disabled={
                [PAT_ROLE.PRICING_EDIT, PAT_ROLE.AUCTION_EDIT].includes(role) &&
                value
            }
        />
    )
}
