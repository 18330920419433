import React, { useEffect, useState } from 'react'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { deleteStorage, getStorage } from '../utils/storageService'
import { FEE_SCHEDULE_STORAGE_KEY } from '../utils/constants'
import {
    publishFeeSchedule,
    usePreviewFeeChanges
} from '../service/feeScheduleService'
import {
    PrimaryActionButton,
    SecondaryActionButton
} from '../components/InputControls'
import { Tooltip } from '../components/ToolTip'
import { RateCardOnTooltip } from '../components/ratecard/RateCardOnTooltip'
import { InfoIcon } from '../components/Icons'
import { describeFeeStructureTierFormat } from '../utils/Formatters'
import { Table } from '@interstate/components/Table'
import { CheckBox } from '@interstate/components/CheckBox'
import { Grid } from '@interstate/components/Grid'
import { renderDateFromIsoString } from '../utils/Formatters'

export function ReviewFeesView(props) {
    const navigate = useNavigate()
    const [draftFeeSchedule, setDraftFeeSchedule] = useState()
    const [overlappingFeeTableData, setOverlappingFeeTableData] = useState([])
    const [saleTypeFeeTableData, setSaleTypeFeeTableData] = useState([])
    const [expireFeeScheduleIds, setExpireFeeScheduleIds] = useState([])
    const [overlappingFeeScheduleFlatList, setOverlappingFeeScheduleFlatList] =
        useState([])

    useEffect(() => {
        console.log('Parsing feeSchedule from Local Storage...')
        setDraftFeeSchedule(JSON.parse(getStorage(FEE_SCHEDULE_STORAGE_KEY)))
    }, [])

    useEffect(() => {
        if (draftFeeSchedule?.saleTypeFeeList) {
            let denormalizedSaleTypeFeeList = []
            draftFeeSchedule?.saleTypeFeeList.forEach((saleTypeFee) => {
                let overridesJson = null
                if (saleTypeFee.overrides) {
                    let overridesString = JSON.stringify(saleTypeFee.overrides)
                    overridesJson = JSON.parse(overridesString)
                }

                delete saleTypeFee.overrides
                denormalizedSaleTypeFeeList.push(saleTypeFee)
                if (overridesJson && overridesJson.length > 0) {
                    overridesJson.forEach((override) => {
                        override.feeCode = saleTypeFee.feeCode
                        override.saleType = saleTypeFee.saleType
                        denormalizedSaleTypeFeeList.push(override)
                    })
                }
            })

            setSaleTypeFeeTableData(denormalizedSaleTypeFeeList)
        }
    }, [draftFeeSchedule])

    const {
        value: feeSchedulePreview,
        loading: feeSchedulePreviewLoading,
        error: feeSchedulePreviewError
    } = usePreviewFeeChanges(draftFeeSchedule)

    useEffect(() => {
        if (
            feeSchedulePreview &&
            feeSchedulePreview.overlappingFeeScheduleFlatList
        ) {
            setOverlappingFeeScheduleFlatList(
                feeSchedulePreview.overlappingFeeScheduleFlatList
            )
            const _tableData =
                feeSchedulePreview.overlappingFeeScheduleFlatList.map(
                    (feeScheduleFlat) => {
                        return {
                            key: feeScheduleFlat.id,
                            auctionCode: feeScheduleFlat.buCode,
                            customer: feeScheduleFlat.customer,
                            feeType: feeScheduleFlat.feeType,
                            saleType: feeScheduleFlat.saleType,
                            startDate: feeScheduleFlat.startDate,
                            endDate: feeScheduleFlat.endDate,
                            feeStructure: (
                                <Tooltip
                                    content={
                                        <RateCardOnTooltip
                                            feeStructure={
                                                feeScheduleFlat.feeStructure
                                            }
                                        />
                                    }
                                    direction="bottom">
                                    <span>
                                        {describeFeeStructureTierFormat(
                                            feeScheduleFlat.feeStructure
                                        )}
                                        &nbsp;
                                        <InfoIcon />
                                    </span>{' '}
                                    &nbsp;
                                </Tooltip>
                            ),
                            expireFeeSchedule: (
                                <CheckBox
                                    data-testid="checkbox-1-data-testid"
                                    id="expireFeeSchedule"
                                    label=""
                                    name="expireFeeSchedule"
                                    value={feeScheduleFlat.id}
                                    onChange={handleCheckboxChange}
                                />
                            )
                        }
                    }
                )
            setOverlappingFeeTableData(_tableData)
        }
    }, [feeSchedulePreview])

    // Handle change event for checkboxes
    const handleCheckboxChange = (event) => {
        const { checkboxValue, checked } = event.target.value

        if (checked) {
            // Add the value to the array when checked
            setExpireFeeScheduleIds((prev) => [...prev, checkboxValue])
        } else {
            // Remove the value from the array when unchecked
            setExpireFeeScheduleIds((prev) =>
                prev.filter((val) => val !== checkboxValue)
            )
        }
    }

    function publishFees() {
        // if(!feeChanges){
        //     return;
        // }
        // const currentLocations = new Set(feeChanges.expiringLaneFees.map(lf => lf.businessUnit));
        // const newLocations = new Set(feeChanges.newLaneFees.map(lf => lf.businessUnit));
        // const allAffectedLocations = [...new Set([...currentLocations, ...newLocations])];
        //
        // //todo: i THINK this works...
        // const feesCheckVersion = feeChanges.expiringLaneFees.map(lf => lf.createdDateTime).reduce((previous, current) => previous > current ? previous : current)
        //

        //send the complete FeeScheduleFlat object that needs to be deleted
        let expireFeeScheduleFlatList = []
        if (expireFeeScheduleIds.length > 0) {
            expireFeeScheduleIds.forEach((expireFeeScheduleId) => {
                expireFeeScheduleFlatList.push(
                    overlappingFeeScheduleFlatList.find(
                        (overlappingFeeScheduleFlat) =>
                            overlappingFeeScheduleFlat.id ===
                            expireFeeScheduleId
                    )
                )
            })
        }

        publishFeeSchedule({
            feeSchedule: feeSchedulePreview.feeSchedule,
            expireFeeScheduleFlatList: expireFeeScheduleFlatList
        }).then((publishResult) => {
            // setPublishResults(publishResult);
            // if(publishResult){
            //     setShowPublishResults(true);
            // }

            if (publishResult?.length > 0) {
                const queryParams = {
                    fsg: publishResult[0].feeScheduleGroup
                }

                deleteStorage(FEE_SCHEDULE_STORAGE_KEY)
                navigate({
                    pathname: '/boc/feeScheduleConfirmation',
                    search: `?${createSearchParams(queryParams)}`
                })
            }
        })
    }

    function displayFeeStructure(saleTypeFee) {
        return (
            <Tooltip
                content={
                    <RateCardOnTooltip
                        feeStructure={saleTypeFee.feeStructure}
                    />
                }
                direction="bottom">
                <span>
                    {describeFeeStructureTierFormat(saleTypeFee.feeStructure)}
                    &nbsp;
                    <InfoIcon />
                </span>{' '}
                &nbsp;
            </Tooltip>
        )
    }

    function displayAuctionList(saleTypeFee) {
        let auctionCodesDisplay = saleTypeFee.auctionCodeList?.join(', ')
        if (
            saleTypeFee.excludedAuctionList &&
            saleTypeFee.excludedAuctionList.length > 0
        ) {
            auctionCodesDisplay +=
                ' (Excluded: ' +
                saleTypeFee.excludedAuctionList.join(', ') +
                ')'
        }
        return auctionCodesDisplay
    }

    function backToAddExhibit() {
        navigate('/boc/addFeeSchedule')
    }

    return (
        <div>
            {!feeSchedulePreviewLoading && !feeSchedulePreviewError && (
                <>
                    <div>
                        {draftFeeSchedule && (
                            <div>
                                <Grid container spacing={2}>
                                    <Grid xs={12} md={4}>
                                        <div>Contract type</div>
                                        <div>
                                            {draftFeeSchedule.feeScheduleType}
                                        </div>
                                    </Grid>
                                    <Grid xs={12} md={4}>
                                        <div>Start date</div>
                                        <div>
                                            {renderDateFromIsoString(
                                                draftFeeSchedule.startDate,
                                                'MMM DD YYYY'
                                            )}
                                        </div>
                                    </Grid>

                                    <Grid xs={12} md={4}>
                                        <div>End date</div>
                                        <div>
                                            {renderDateFromIsoString(
                                                draftFeeSchedule.endDate,
                                                'MMM DD YYYY'
                                            )}
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid xs={12} md={4}>
                                        <div>Account Number(s)</div>
                                        <div>
                                            {draftFeeSchedule.accountNumbers}
                                        </div>
                                    </Grid>
                                    <Grid xs={12} md={4}>
                                        <div>Group Code(s)</div>
                                        <div>{draftFeeSchedule.groupCodes}</div>
                                    </Grid>

                                    <Grid xs={12} md={4}>
                                        <div>Alt Group Code(s)</div>
                                        <div>
                                            {draftFeeSchedule.altGroupCodes}
                                        </div>
                                    </Grid>
                                </Grid>
                                <div>&nbsp;</div>
                                <div>&nbsp;</div>
                                {saleTypeFeeTableData &&
                                    saleTypeFeeTableData.length > 0 && (
                                        <Table
                                            columns={[
                                                {
                                                    className:
                                                        'column-auctionCodes',
                                                    render: (item) => (
                                                        <div>
                                                            {displayAuctionList(
                                                                item
                                                            )}
                                                        </div>
                                                    ),
                                                    title: 'Auction codes'
                                                },
                                                {
                                                    className: 'column-feeType',
                                                    dataIndex: 'feeCode',
                                                    title: 'Fee type'
                                                },
                                                {
                                                    className:
                                                        'column-saleType',
                                                    dataIndex: 'saleType',
                                                    title: 'Sale type'
                                                },
                                                {
                                                    className:
                                                        'column-feeStructure',
                                                    render: (item) => (
                                                        <div>
                                                            {displayFeeStructure(
                                                                item
                                                            )}
                                                        </div>
                                                    ),
                                                    title: 'Fee structure'
                                                }
                                            ]}
                                            data={saleTypeFeeTableData}
                                            tableLayout={'auto'}
                                            enablePagination={true}
                                            id="saleTypeFeeTable"
                                            onChangePage={function Ua() {}}
                                            onChangePageSize={function Ua() {}}
                                            onChangeSelectedRows={function Ua() {}}
                                            onChangeSorter={function Ua() {}}
                                            onExpandClick={function Ua() {}}
                                            onRowCallback={function Ua() {}}
                                            onRowSelect={function Ua() {}}
                                        />
                                    )}
                            </div>
                        )}
                    </div>

                    <div>&nbsp;</div>
                    <div>
                        The following fees are overlapping with the new fees.
                    </div>

                    <Table
                        columns={[
                            {
                                className: 'column-auctionCode',
                                dataIndex: 'auctionCode',
                                render: (item) => <div>{item}</div>,
                                title: 'Auction code'
                            },
                            {
                                className: 'column-customer',
                                dataIndex: 'customer',
                                title: 'Customer'
                            },
                            {
                                className: 'column-feeType',
                                dataIndex: 'feeType',
                                title: 'Fee type'
                            },
                            {
                                className: 'column-saleType',
                                dataIndex: 'saleType',
                                title: 'Sale type'
                            },
                            {
                                className: 'column-startDate',
                                dataIndex: 'startDate',
                                render: (item) => (
                                    <div>
                                        {renderDateFromIsoString(
                                            item,
                                            'MMM DD YYYY'
                                        )}
                                    </div>
                                ),
                                title: 'Start date'
                            },
                            {
                                className: 'column-endDate',
                                dataIndex: 'endDate',
                                render: (item) => (
                                    <div>
                                        {renderDateFromIsoString(
                                            item,
                                            'MMM DD YYYY'
                                        )}
                                    </div>
                                ),
                                title: 'End date'
                            },
                            {
                                className: 'column-feeStructure',
                                dataIndex: 'feeStructure',
                                title: 'Fee structure'
                            },
                            {
                                className: 'column-expire',
                                dataIndex: 'expireFeeSchedule',
                                title: 'Expire'
                            }
                        ]}
                        data={overlappingFeeTableData}
                        tableLayout={'auto'}
                        enablePagination={true}
                        id="overlappingFeesTable"
                        onChangePage={function Ua() {}}
                        onChangePageSize={function Ua() {}}
                        onChangeSelectedRows={function Ua() {}}
                        onChangeSorter={function Ua() {}}
                        onExpandClick={function Ua() {}}
                        onRowCallback={function Ua() {}}
                        onRowSelect={function Ua() {}}
                    />
                </>
            )}

            <div>
                <PrimaryActionButton
                    label="Approve"
                    onClick={publishFees}></PrimaryActionButton>
                <SecondaryActionButton
                    label="Back to Fee Schedule"
                    onClick={backToAddExhibit}></SecondaryActionButton>
            </div>
        </div>
    )
}
