import { React } from 'react'
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable
} from '@tanstack/react-table'
import {
    dollars,
    renderFeeStructureType,
    describeFeeStructureOldFormat,
    mapRateCardToFeeStructure
} from '../../utils/Formatters'
import { RateCardOnTooltip } from './RateCardOnTooltip'
import { InfoIcon } from '../Icons'
import { Tooltip } from '../ToolTip'

const columnHelper = createColumnHelper()

const renderFeeStructure = (rateCard) => {
    return (
        <>
            <span>{describeFeeStructureOldFormat(rateCard)}</span>
            <Tooltip
                content={
                    <RateCardOnTooltip
                        feeStructure={mapRateCardToFeeStructure(rateCard)}
                    />
                }
                direction="left">
                <span>
                    &nbsp; <InfoIcon />
                </span>{' '}
                &nbsp;
            </Tooltip>
        </>
    )
}

const columns = [
    columnHelper.accessor((row) => row.description, {
        header: 'Description',
        footer: (info) => info.column.id,
        id: 'description'
    }),
    columnHelper.accessor((row) => row.type, {
        header: 'Type',
        cell: (info) => renderFeeStructureType(info.getValue()),
        footer: (info) => info.column.id,
        id: 'type'
    }),
    columnHelper.accessor((row) => row.numTiers, {
        header: '# Tiers',
        footer: (info) => info.column.id,
        id: 'numTiers'
    }),
    columnHelper.accessor(
        (row) => row.priceTier && row.priceTier.length && row.priceTier[0].fee,
        {
            header: 'Start Fee',
            cell: (info) => dollars.format(info.getValue()),
            footer: (info) => info.column.id,
            id: 'startFee'
        }
    ),
    columnHelper.accessor((row) => row, {
        header: 'Fee Structure',
        cell: (info) => renderFeeStructure(info.getValue()),
        footer: (info) => info.column.id,
        id: 'feeStructure'
    })
    // columnHelper.accessor(row => row.createdDateTime, {
    //   header: 'Created',
    //   cell: info => renderShortDate(info.getValue()),
    //   footer: info => info.column.id,
    //   id: 'createdDateTime'
    // })
]

export function RateCardsTable({ data, onRowClick }) {
    const onRowClickInternal = (row) => {
        console.log('RateCardsTable onRowClick', row)
        if (!!onRowClick) {
            onRowClick(row)
        }
    }

    console.log('rateCardsData:', data)
    const table = useReactTable({
        data: data,
        columns,
        getCoreRowModel: getCoreRowModel()
    })

    return (
        <table className="bocTable rateCardsList">
            <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                            <th key={header.id}>
                                {header.isPlaceholder
                                    ? null
                                    : flexRender(
                                          header.column.columnDef.header,
                                          header.getContext()
                                      )}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody>
                {table.getRowModel().rows.map((row) => (
                    <tr
                        key={row.id}
                        onClick={(evt) => onRowClickInternal(row.original)}>
                        {row.getVisibleCells().map((cell) => (
                            <td key={cell.id}>
                                {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                )}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    )
}
