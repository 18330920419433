export const FEE_SCHEDULE_TYPE = {
    GLOBAL_OVERRIDES: 'Global Overrides',
    CONTRACT_OVERRIDES: 'Contract Overrides',
    NATIONAL_CONTRACTS: 'National Contracts',
    LOCAL_DEALS: 'Local Deals',
    CONTRACTS_BLD: 'Contracts BLD',
    DEFAULTS: 'Defaults'
}

export const FEE_TYPE = {
    SELLER: 'SELLER',
    SLIST: 'SLIST',
    INTSELL: 'INTSELL',
    SELLBYS: 'SELLBYS',
    SELLBUY: 'SELLBUY',
    BUYER: 'BUYER',
    BLIST: 'BLIST',
    INTBUY: 'INTBUY',
    SIMBFE: 'SIMBFE',
    SSELL: 'SSELL',
    BBUY: 'BBUY'
}

export const PAT_ROLE = {
    ADMIN: 'ADMIN',
    BOC_EDIT: 'BOC_EDIT',
    AUCTION_EDIT: 'AUCTION_EDIT',
    PRICING_EDIT: 'PRICING_EDIT'
}
