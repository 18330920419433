import { SlideOut } from '@interstate/components/SlideOut'
import { CreateRateCard } from '../ratecard/CreateRateCard'
import { React } from 'react'

export function RateCardCreateSlideOut({
    show,
    close,
    toggle,
    currentFeeOverrideIndex,
    onRateCardSelection,
    moduleid
}) {
    return (
        <SlideOut
            header={<h4>Create Rate Card</h4>}
            show={show}
            onHide={close}
            panelWidth="700px"
            data-testid={'RateCardCreate' + moduleid}>
            <CreateRateCard
                onRateCardSelection={onRateCardSelection}
                feeOverrideIndex={currentFeeOverrideIndex}
                toggleRateCardCreateModal={toggle}
                moduleid={moduleid}
            />
        </SlideOut>
    )
}
