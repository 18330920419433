import { Alert } from '@interstate/components/Alert'

export function PatErrorAlert({ error, text }) {
    return (
        <>
            {error && (
                <Alert type="error" title="Error">
                    {text || error}
                </Alert>
            )}
        </>
    )
}
