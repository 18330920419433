import moment from 'moment'

const emailRegEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

export function isEmptyString(val) {
    if (val && val.length > 0) {
        return false
    }
    return true
}

export function isEmptyArray(arr) {
    if (arr && arr.length > 0) {
        return false
    }
    return true
}

export function isNumeric(str) {
    return !isNaN(parseFloat(str)) && isFinite(str)
}

export function isValidEmail(email) {
    return emailRegEx.test(email)
}

export function nonEmptyErrors(errors) {
    if (Object.keys(errors).length === 0) {
        return null
    }
    return errors
}

export function isDateAfterToday(dateString) {
    return moment(dateString).isAfter(moment())
}
