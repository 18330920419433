import { useAuth } from '../auth/AuthProvider'
import { Alert } from '@interstate/components/Alert'

export function RoleWrapper({ roles, children, alert = false, locationId }) {
    const authService = useAuth()
    const role = authService?.principal?.role

    const isDisabled = () => {
        const roleLocationId =
            authService?.principal?.locationId?.split(',') || []
        if (roles.includes(role)) {
            if (['AUCTION_EDIT'].includes(role)) {
                if (
                    locationId &&
                    roleLocationId &&
                    !roleLocationId.includes(locationId)
                ) {
                    return true
                }
            }
            return false
        }
        return true
    }

    return (
        <>
            {!isDisabled() && children}

            {alert && isDisabled() && (
                <div className="unauthorized">
                    <Alert type="Error" title="Unauthorized.">
                        You are not authorized to view this page{' '}
                    </Alert>
                </div>
            )}
        </>
    )
}
