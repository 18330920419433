import { Routes, Route } from 'react-router-dom'
import { EditFeeScheduleView } from '../views/EditFeeScheduleView'
import { ReviewFeesView } from '../views/ReviewFeesView'
import { BocSearchView } from '../views/BocSearchView'
import { FeeResultsView } from '../views/FeeResultsView'
import { RateCardsView } from '../views/RateCardsView'
import { RateCardsResultsView } from '../views/RateCardsResultsView'
import { FeeScheduleStatusView } from '../views/FeeScheduleStatusView'
import { FeeScheduleStatusResultsView } from '../views/FeeScheduleStatusResultsView'
import { EditFeeScheduleConfirmation } from '../views/EditFeeScheduleConfirmation'

function BocRoute() {
    return (
        <Routes>
            {/*Todo: Consider combining search input and results into a single page*/}
            <Route exact path="searchFee" element={<BocSearchView />} />
            <Route exact path="searchFeeResults" element={<FeeResultsView />} />
            <Route
                exact
                path="addFeeSchedule"
                element={<EditFeeScheduleView />}
            />
            <Route
                exact
                path="feeScheduleStatus"
                element={<FeeScheduleStatusView />}
            />
            <Route
                exact
                path="feeScheduleStatusResults"
                element={<FeeScheduleStatusResultsView />}
            />
            <Route exact path="reviewFees" element={<ReviewFeesView />} />
            <Route
                exact
                path="feeScheduleConfirmation"
                element={<EditFeeScheduleConfirmation />}
            />
            <Route exact path="feeResults" element={<FeeResultsView />} />
            <Route exact path="ratecards" element={<RateCardsView />} />
            <Route
                exact
                path="ratecardResults"
                element={<RateCardsResultsView />}
            />
            <Route exact path="getFees" element={<RateCardsResultsView />} />
            <Route path=" " element={<div>Root BOC</div>} />
        </Routes>
    )
}

export default BocRoute
