import { Switch } from '@interstate/components/Switch'
import { ThreeDotAction } from '../../components/ThreeDotAction'
import { Table } from '@interstate/components/Table'
import { React, useState } from 'react'
import { TextInput } from '@interstate/components/TextInput'
import { useAuth } from '../../auth/AuthProvider'
import {
    createProcessConfig,
    updateProcessConfig
} from '../../service/configService'
import { PatErrorAlert } from '../../components/common/PatErrorAlert'

export function AuctionConfigurationTable({ data, onEdit }) {
    const [editor, setEditor] = useState(null)
    const [toggle, setToggle] = useState(false)
    const [low, setLow] = useState(null)
    const [high, setHigh] = useState(null)
    const [error, setError] = useState(null)

    const authService = useAuth()
    const role = authService?.principal?.role

    const handleEdit = (row, flip) => {
        setError(null)
        setEditor(row)
        if (flip) setToggle(!row.prodToggle)
        else setToggle(row.prodToggle)
        setLow(row.slopeLow)
        setHigh(row.slopeHigh)
    }

    const handleRest = (row, attr, configType, val, cStatus, cValue) => {
        if (row[attr] !== val) {
            const pt = {
                buCode: row.locationId,
                configType: configType,
                configStatus: cStatus,
                configValue: cValue
            }
            if (row[attr + 'Id'] === 'ALL') {
                //create
                return createProcessConfig(pt)
            } else {
                //update
                return updateProcessConfig(pt, row[attr + 'Id'])
            }
        } else {
            return Promise.resolve()
        }
    }

    const handleSave = (row) => {
        if (Number(low) > Number(high)) {
            setError('Slope Low cannot be greater than Slope High')
            return
        }
        let promiseChain = []
        promiseChain.push(
            handleRest(
                row,
                'prodToggle',
                'CHARGE_UPDATE',
                toggle,
                toggle ? 'ON' : 'OFF',
                ''
            )
        )
        promiseChain.push(
            handleRest(row, 'slopeLow', 'SLOPE_BPRICE', low, 'ON', low)
        )
        promiseChain.push(
            handleRest(row, 'slopeHigh', 'SLOPE_EPRICE', high, 'ON', high)
        )

        Promise.all(promiseChain)
            .then((p1, p2, p3) => {
                setError(null)
                setEditor(null)
                onEdit()
            })
            .catch((e) => {
                console.log(e)
                setError(e)
                setEditor(null)
                onEdit()
            })
    }

    return (
        <>
            <Table
                tableLayout={'auto'}
                sortableColumns={true}
                highlightOnHover={true}
                headerBackgroundColor="dark"
                columns={[
                    {
                        title: 'Location Name',
                        dataIndex: 'locationName'
                    },
                    {
                        title: 'Location Code',
                        dataIndex: 'locationId'
                    },
                    {
                        title: 'Slope Configuration',
                        render: (row) => {
                            return (
                                <div>
                                    {editor && row.key === editor.key && (
                                        <>
                                            <PatErrorAlert error={error} />
                                            <TextInput
                                                label="Slope Low"
                                                value={low}
                                                onChange={(e) => {
                                                    setLow(e.target.value)
                                                }}
                                            />
                                            <TextInput
                                                label="Slope High"
                                                value={high}
                                                onChange={(e) => {
                                                    setHigh(e.target.value)
                                                }}
                                            />
                                        </>
                                    )}
                                    {!(editor && row.key === editor.key) && (
                                        <>{row.slope}</>
                                    )}
                                </div>
                            )
                        }
                    },
                    {
                        title: 'Live in Prod',
                        render: (row) => {
                            return (
                                <>
                                    {!(editor && row.key === editor.key) && (
                                        <Switch
                                            checked={row.prodToggle}
                                            disabled={!['ADMIN'].includes(role)}
                                        />
                                    )}
                                    {editor && row.key === editor.key && (
                                        <Switch
                                            checked={toggle}
                                            onClick={() => {
                                                setToggle(!toggle)
                                            }}
                                        />
                                    )}
                                </>
                            )
                        }
                    },
                    {
                        title: '',
                        columnSortable: false,
                        className: 'text-right',
                        render: (row) => (
                            <ThreeDotAction
                                item={row}
                                roles={['ADMIN']}
                                inLineSave={editor && row.key === editor.key}
                                onEdit={handleEdit}
                                onCancel={() => {
                                    setEditor(null)
                                }}
                                onSave={handleSave}
                            />
                        )
                    }
                ]}
                data={data}
                defaultPageSize={25}
            />
        </>
    )
}
