import moment from 'moment'
import { isEmptyString, isNumeric } from './commonValidations'

export const RequiredCustomerIds = [
    'Contract Overrides',
    'National Contracts',
    'Local Deals',
    'Contracts BLD'
]

export function customerIdsRequired(feeScheduleType) {
    return RequiredCustomerIds.includes(feeScheduleType)
}

export function validateFeeSchedule(feeScheduleModel) {
    let newErrorMessages = {}

    if (!feeScheduleModel.startDate) {
        newErrorMessages.startDate = 'Start Date is required'
    } else if (
        !!feeScheduleModel.endDate &&
        moment(feeScheduleModel.startDate).isAfter(
            moment(feeScheduleModel.endDate)
        )
    ) {
        newErrorMessages.startDate = 'Start Date must be before End Date.'
    }

    // if(!feeScheduleModel.customerIds?.length && customerIdsRequired(feeScheduleModel.feeScheduleType)){
    //     newErrorMessages.customerIds = 'At least one Group Code or Account # is required.'
    // }

    if (!feeScheduleModel.saleTypeFeeList?.length) {
        newErrorMessages.saleTypeFeeList =
            'At least one Sale Type and Fee Type must be specified.'
    } else if (
        feeScheduleModel.saleTypeFeeList.some(
            (stf) =>
                !stf.saleType ||
                !stf.feeCode ||
                !stf.auctionCodeList?.length ||
                !stf.feeStructure
        )
    ) {
        newErrorMessages.saleTypeFeeList =
            'Every section must specify a Sale Type, Fee Type, one or more Auction Locations, and a Rate Card.'
    }

    return newErrorMessages
}

export function validateSearchRateCard({
    numTiers,
    startFee,
    tier2Fee,
    tier3Fee,
    tier4Fee
}) {
    let newErrorMessages = {}

    if (isEmptyString(numTiers)) {
        newErrorMessages.numTiers = 'Tiers is required.'
    } else if (!isNumeric(numTiers)) {
        newErrorMessages.numTiers = 'Tiers must be numeric.'
    }

    if (isEmptyString(startFee)) {
        newErrorMessages.startFee = 'Start Fee is required.'
    } else if (!isNumeric(startFee)) {
        newErrorMessages.startFee = 'Start Fee must be numeric.'
    }

    if (!isEmptyString(tier2Fee) && !isNumeric(tier2Fee)) {
        newErrorMessages.tier2Fee = 'Tier 2 Fee must be numeric.'
    }

    if (!isEmptyString(tier3Fee) && !isNumeric(tier3Fee)) {
        newErrorMessages.tier3Fee = 'Tier 3 Fee must be numeric.'
    }

    if (!isEmptyString(tier4Fee) && !isNumeric(tier4Fee)) {
        newErrorMessages.tier4Fee = 'Tier 4 Fee must be numeric.'
    }

    return newErrorMessages
}
