import { describePercentOrAdds, dollarsFull } from '../../utils/Formatters'

export function RateCardOnTooltip({ feeStructure }) {
    if (feeStructure) {
        const percentOrAdds = describePercentOrAdds(feeStructure)
        return (
            <div>
                <table className="bocRateDetail">
                    <tbody>
                        {feeStructure.feeTiers &&
                            feeStructure.feeTiers.length > 0 && (
                                <tr>
                                    <th>Low limit</th>
                                    <th>High Limit</th>
                                    <th>Fee</th>
                                </tr>
                            )}
                        {feeStructure.feeTiers.map((eachTier, index) => {
                            if (index === 0) {
                                return (
                                    <tr key={index}>
                                        <td>{dollarsFull.format(0)}</td>
                                        <td>
                                            {dollarsFull.format(
                                                eachTier.highLimit
                                            )}
                                        </td>
                                        <td>
                                            {dollarsFull.format(eachTier.fee)}
                                        </td>
                                    </tr>
                                )
                            } else {
                                return (
                                    <tr key={index}>
                                        <td>
                                            {dollarsFull.format(
                                                feeStructure.feeTiers[index - 1]
                                                    .highLimit + 1
                                            )}
                                        </td>
                                        <td>
                                            {dollarsFull.format(
                                                eachTier.highLimit
                                            )}
                                        </td>
                                        <td>
                                            {dollarsFull.format(eachTier.fee)}
                                        </td>
                                    </tr>
                                )
                            }
                        })}
                    </tbody>
                </table>
                {percentOrAdds && percentOrAdds.length > 0 && (
                    <div>
                        <div>&nbsp;</div>
                        <div>Add {percentOrAdds}</div>
                    </div>
                )}
            </div>
        )
    } else {
        return <span>No info available</span>
    }
}
