export function auctionDisplayUtil(code, context) {
    let display = ''
    let notfound = true
    context?.auctions.every((auction) => {
        if (auction.locationCode === code) {
            display = `${auction.state}-${auction.locationName} (${auction.locationCode})`
            notfound = false
        }
        return notfound
    })
    return display
}

export function sortByAuctionsUtil(auctions, context, param = 'auctionId') {
    return auctions.sort((a, b) => {
        let fa = auctionDisplayUtil(a[param], context),
            fb = auctionDisplayUtil(b[param], context)

        if (fa < fb) {
            return -1
        }
        if (fa > fb) {
            return 1
        }
        return 0
    })
}

export function auctionOptionsUtil(context, all = false, auth = null) {
    let auctions = context?.auctions
        ?.map((auction) => {
            return {
                value: auction.locationCode,
                text: `${auction.state}-${auction.locationName} (${auction.locationCode})`,
                label: `${auction.state}-${auction.locationName} (${auction.locationCode})`
            }
        })
        .sort((a, b) => a.value.localeCompare(b.value))

    if (auth) {
        if (auth.role === 'AUCTION_EDIT') {
            let ids = auth.locationId.split(',')
            auctions = auctions.filter((a) => ids.includes(a.value))
            return auctions
        }
    }

    if (all) {
        return [{ value: 'ALL', text: 'ALL', label: 'ALL' }, ...auctions]
    } else {
        return auctions
    }
}
