import { SlideOut } from '@interstate/components/SlideOut'
import { RateCardSearch } from '../ratecard/RateCardSearch'

export function RateCardSearchSlideOut({
    show,
    close,
    allRateCardsList,
    toggle,
    currentFeeOverrideIndex,
    onRateCardSelection,
    moduleid
}) {
    return (
        <SlideOut
            header={<h4>Search Rate Card</h4>}
            show={show}
            onHide={close}
            panelWidth="700px"
            data-testid={'RateCardSearch' + moduleid}>
            <RateCardSearch
                allRateCardsList={allRateCardsList}
                toggleRateCardCreateModal={toggle}
                onRateCardSelection={(rateCard) =>
                    onRateCardSelection(
                        rateCard,
                        currentFeeOverrideIndex,
                        false
                    )
                }
                moduleid={moduleid}
            />
        </SlideOut>
    )
}
