import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { PageHeader } from '../components/PageHeader'
import { Container } from '../components/Container'
import { useFeeScheduleByGroupId } from '../service/feeScheduleService'
import {
    describeFeeStructureTierFormat,
    renderDateFromIsoString
} from '../utils/Formatters'
import { Table } from '@interstate/components/Table'
import { Tooltip } from '../components/ToolTip'
import { RateCardOnTooltip } from '../components/ratecard/RateCardOnTooltip'
import { InfoIcon } from '../components/Icons'

export function EditFeeScheduleConfirmation(props) {
    let [searchParams] = useSearchParams()
    const feeScheduleGroupId = searchParams.get('fsg')
    const [createdFeeScheduleFlatList, setCreatedFeeScheduleFlatList] =
        useState([])

    const {
        value: feeScheduleFlatList,
        loading: feeScheduleFlatListLoading,
        error: feeScheduleFlatListError
    } = useFeeScheduleByGroupId(feeScheduleGroupId)

    useEffect(() => {
        if (feeScheduleFlatList) {
            const _tableData = feeScheduleFlatList.map((feeScheduleFlat) => {
                return {
                    key: feeScheduleFlat.id,
                    auctionCode: feeScheduleFlat.buCode,
                    customer: feeScheduleFlat.customer,
                    feeType: feeScheduleFlat.feeType,
                    saleType: feeScheduleFlat.saleType,
                    startDate: feeScheduleFlat.startDate,
                    endDate: feeScheduleFlat.endDate,
                    feeStructure: (
                        <Tooltip
                            content={
                                <RateCardOnTooltip
                                    feeStructure={feeScheduleFlat.feeStructure}
                                />
                            }
                            direction="bottom">
                            <span>
                                {describeFeeStructureTierFormat(
                                    feeScheduleFlat.feeStructure
                                )}
                                &nbsp;
                                <InfoIcon />
                            </span>{' '}
                            &nbsp;
                        </Tooltip>
                    )
                }
            })
            setCreatedFeeScheduleFlatList(_tableData)
        }
    }, [feeScheduleFlatList])

    return (
        <Container>
            <PageHeader titleValue="Fee Schedule Confirmation" />
            <div>
                <h1>Fee Schedule Confirmation</h1>
            </div>
            {!feeScheduleFlatListError && !feeScheduleFlatListLoading && (
                <div>
                    <Table
                        columns={[
                            {
                                className: 'column-auctionCode',
                                dataIndex: 'auctionCode',
                                render: (item) => <div>{item}</div>,
                                title: 'Auction code'
                            },
                            {
                                className: 'column-customer',
                                dataIndex: 'customer',
                                title: 'Customer'
                            },
                            {
                                className: 'column-feeType',
                                dataIndex: 'feeType',
                                title: 'Fee type'
                            },
                            {
                                className: 'column-saleType',
                                dataIndex: 'saleType',
                                title: 'Sale type'
                            },
                            {
                                className: 'column-startDate',
                                dataIndex: 'startDate',
                                render: (item) => (
                                    <div>
                                        {renderDateFromIsoString(
                                            item,
                                            'MMM DD YYYY'
                                        )}
                                    </div>
                                ),
                                title: 'Start date'
                            },
                            {
                                className: 'column-endDate',
                                dataIndex: 'endDate',
                                render: (item) => (
                                    <div>
                                        {renderDateFromIsoString(
                                            item,
                                            'MMM DD YYYY'
                                        )}
                                    </div>
                                ),
                                title: 'End date'
                            },
                            {
                                className: 'column-feeStructure',
                                dataIndex: 'feeStructure',
                                title: 'Fee structure'
                            }
                        ]}
                        data={createdFeeScheduleFlatList}
                        tableLayout={'auto'}
                        enablePagination={true}
                        id="feeScheudleConfirmationTable"
                        onChangePage={function Ua() {}}
                        onChangePageSize={function Ua() {}}
                        onChangeSelectedRows={function Ua() {}}
                        onChangeSorter={function Ua() {}}
                        onExpandClick={function Ua() {}}
                        onRowCallback={function Ua() {}}
                        onRowSelect={function Ua() {}}
                    />
                </div>
            )}
        </Container>
    )
}
